import mainStore from '@/store'
import creditNote from '@/store/main/orders/credit-note'
import {
  FLAT_PICKER,
  TEXT_INPUT,
  SELECT_INPUT,
  TEXTAREA_INPUT,
  SELECT_CHILD_INPUT, L_CURRENCY_MASK_INPUT,
} from '@/views/components/DynamicForm/constants'
import { formatNumberToCurrencyView, getValueFromGivenObjectByKey } from '@core/utils/utils'
import mainConfig from '../../config'

// Get Order Statuses
const {
  ORDERS_STATUS_READY,
  ORDERS_STATUS_VOID,
} = mainConfig()

export default function config() {
  // Constants
  const MODULE_NAME = 'credit-note'
  const MODULE_NAME_ORDERS = 'orders'
  const MODULE_NAME_CLONE = 'cloneData'
  const TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE = 'Totals'
  const TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL = 'TOTAL'

  const store = mainStore
  const model = creditNote

  const ORDERS_STATUS_READY_STATUS = ORDERS_STATUS_READY.status
  const ORDERS_STATUS_REJECTED = ORDERS_STATUS_VOID.status
  const ORDERS_STATUS_AS_DRAFT = 1
  const ORDERS_STATE_CLOSED_ORDER = 2

  const SUPPLIER_INFORMATION_TITLE = 'Supplier Information'
  const CREDIT_TITLE = 'Credit'

  const customerInformationFields = {
    customer_id: {
      label: 'Customer Name',
    },
    required_by: {
      type: FLAT_PICKER,
      label: 'Required by',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          minDate: new Date(),
        },
      },
    },
    billing_contact: {
      type: SELECT_CHILD_INPUT,
      label: 'Billing Contact',
      store: 'billingContact',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'fullName',
      },
    },
    billing_phone: {
      type: TEXT_INPUT,
      label: 'Billing Phone',
      placeholder: ' ',
    },
    billing_email: {
      type: TEXT_INPUT,
      label: 'Billing Email',
      placeholder: ' ',
    },
    billing_address: {
      type: TEXTAREA_INPUT,
      label: 'Billing Address',
      placeholder: ' ',
    },
    payment_term_id: {
      type: SELECT_INPUT,
      label: 'Payment Terms',
      store: 'paymentTermList',
      rules: 'required',
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      options: {
        label: 'label',
      },
    },
    linked_order_id: {
      type: TEXT_INPUT,
      label: 'Linked Order',
      // store: 'paymentTermList',
      // rules: 'required',
      options: {
        label: 'label',
      },
    },
    internal_account_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal Customer Notes ( not visible to the customer )',
      placeholder: ' ',
    },
    internal_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Notes (not visible to the customer)',
      placeholder: 'Write notes here...',
    },
    external_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes ( visible to the supplier )',
      placeholder: 'Write notes here...',
    },
    attachments: {
      label: 'Attachments',
    },
  }

  const totalsFields = {
    total_order_notes: {
      type: TEXTAREA_INPUT,
      label: 'Credit Note Order Notes (visible to the customer)',
      placeholder: 'Write notes here...',
      options: {
        rows: 8,
      },
    },
  }

  const totalsComputedTable = [
    {
      title: 'Items',
      key: 'rent',
    },
    {
      title: 'Other Charges',
      key: 'sale_other_charges',
    },
    {
      title: 'Discounts',
      key: 'discount',
    },
    {
      title: 'Taxes',
      key: 'tax',
    },
  ]

  const creditModel = {
    name: '',
    unit: '1',
    discount: null,
    subtotal: null,
    tax: null,
    // totals: null,
  }
  const suggestionTableColumnsSupplier = [
    {
      key: 'cust_name_dba',
      label: 'Company',
    },
    {
      key: 'first_name',
      label: 'Contact',
      getValue: (value, data) => `
      ${getValueFromGivenObjectByKey(data, 'default_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'default_contact.last_name', '')} 
      `,
    },
  ]

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
      thStyle: {
        width: '8%',
      },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      thStyle: {
        width: '13%',
      },
      isSortable: true,
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: {
        width: '10%',
      },
      isSortable: true,
    },
    {
      key: 'supplier',
      label: 'From',
      thStyle: {
        width: '20%',
      },
      isSortable: true,
    },
    {
      key: 'internal_order_reference',
      label: 'Reference',
      thStyle: {
        width: '27%',
      },
      isSortable: true,
    },
    {
      key: 'amount',
      label: 'Amount ',
      thStyle: {
        textAlign: 'right',
        width: '12%',
        paddingRight: '12px',
      },
      isSortable: true,
    },
  ]
  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Credit Note',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Paid',
      iconSize: '26',
    },
  ]

  // const tableColumnsOtherCharges = [
  //   {
  //     key: 'actions',
  //     label: '',
  //     thStyle: { width: '5%' },
  //   },
  //   {
  //     key: 'name',
  //     label: 'Name',
  //     thStyle: { width: '58%' },
  //   },
  //   {
  //     key: 'units',
  //     label: 'Units',
  //     thStyle: { width: '8%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'price',
  //     label: 'Price',
  //     thStyle: { width: '8%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'discount',
  //     label: 'Discount',
  //     thStyle: { width: '8%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'taxes',
  //     label: 'Taxes',
  //     thStyle: { width: '8%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'totals',
  //     label: 'Subtotal',
  //     thStyle: { width: '10%', textAlign: 'center' },
  //   },
  // ]
  const chargeModelFields = {
    name: {
      type: TEXT_INPUT,
      label: '',
    },
    unit: {
      type: TEXT_INPUT,
      label: '',
    },
    price: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    subtotal: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    discount: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    tax: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    totals: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
  }
  const tableColumnsCreditItems = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '58%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'tax',
      label: 'Taxes',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'totals',
      label: 'Total',
      thStyle: { width: '10%', textAlign: 'center' },
    },
  ]
  const tableColumnsOtherChargesOnIsNewDirectRequest = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '58%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
  ]

  const orderStatus = [
    {
      icon: 'LQuoteIconFill',
    },
    {
      icon: 'LSignatureIconFill',
    },
    {
      icon: 'LCreditCardIconFill',
    },
    {
      icon: 'LTruckIcon',
    },
    {
      icon: 'LWarehouseIcon',
    },
    {
      icon: 'LBoxSentIcon',
    },
  ]

  const orderInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Dispatch Warehouse',
    },
    {
      key: 'required_by',
      label: 'Required by',
    },
    {
      key: 'dispatch_method.name',
      label: 'Dispatch Method',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')} 
      `,
      label: 'Billing Contact',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'dispatch_contact.firstname', '—')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_contact.lastname', '')} 
      `,
      label: 'Dispatch Contact',
    },
    {
      key: 'billing_contact.phone_one',
      label: 'Billing Phone',
    },
    {
      key: 'dispatch_contact.phone',
      label: 'Dispatch Phone',
    },
    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: 'dispatch_contact.email',
      label: 'Dispatch Email',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'billing_address.line2', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.city', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.state.code', '')} 
      ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.country.three_letter', '')}
      `,
      label: 'Billing Address',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.street', '—')},
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.city', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.state.name', '')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.zip_code', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.country.two_letter', '')} 
      `,
      label: 'Shipping Address',
    },

  ]
  const ORDER_INFORMATION_TITLE = 'Order Information'
  const FORM_BUTTON_TITLE_VOID = 'Void'
  const FORM_BUTTON_TITLE_PRINT = 'Print'
  const FORM_BUTTON_TITLE_SAVE_AS_DRAFT = 'Save as Draft'
  const FORM_BUTTON_TITLE_SEND_BY_EMAIL = 'Send by Email'
  const FORM_BUTTON_TITLE_QUOTE_RECEIVED = 'Create Credit Note'

  const DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID = 'void'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT = 'draft'
  const CREATE_CREDIT_NOTE = 'create'
  const CREATE_CREDIT_NOTE_SENT_BY_EMAIL = 'send_email'

  return {
    store,
    model,
    orderStatus,
    creditModel,
    MODULE_NAME,
    MODULE_NAME_ORDERS,
    MODULE_NAME_CLONE,
    SUPPLIER_INFORMATION_TITLE,
    TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
    TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
    CREDIT_TITLE,
    customerInformationFields,
    tableColumnsCreditItems,
    totalsFields,
    tableColumns,
    totalsComputedTable,
    quoteStatusBar,
    tableColumnsOtherChargesOnIsNewDirectRequest,
    suggestionTableColumnsSupplier,
    ORDERS_STATUS_REJECTED,
    ORDERS_STATUS_AS_DRAFT,
    // tableColumnsOtherCharges,
    chargeModelFields,
    ORDERS_STATE_CLOSED_ORDER,
    orderInformationObjectForRender,
    ORDER_INFORMATION_TITLE,
    ORDERS_STATUS_READY_STATUS,
    FORM_BUTTON_TITLE_VOID,
    FORM_BUTTON_TITLE_PRINT,
    FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
    FORM_BUTTON_TITLE_SEND_BY_EMAIL,
    FORM_BUTTON_TITLE_QUOTE_RECEIVED,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
    CREATE_CREDIT_NOTE,
    CREATE_CREDIT_NOTE_SENT_BY_EMAIL,
  }
}
