import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = () => 'orders/credit-note'
const createCreditNote = (ctx, data) => axios.post(`/${endpoint()}`, data)
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  createCreditNote,
}
