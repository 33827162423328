export default {
  GET(state, data) {
    state.quote = { ...data }
  },
  LIST(state, data) {
    state.quotes = data
  },
  SET_ORDERS_FORM(state, data) {
    state.quoteForm = { ...data }
  },
  STOCK_ITEMS(state, data) {
    state.stockItems = data
  },
  SET_ORDERS_INFORMATION_FORM(state, data) {
    state.orderInformationForm = { ...data }
  },
  LIST_ATTACHMENTS(state, data) {
    state.customerInformationForm.attachments = data
  },
  SET_CUSTOMER_INFORMATION_FORM(state, data) {
    state.customerInformationForm = { ...data }
  },
  // SET_INSURANCE_INFORMATION_FORM(state, data) {
  //   state.insuranceInformationForm = { ...data }
  // },
  SET_DISPATCH_AND_RETURN_FORM(state, data) {
    state.dispatchAndReturnForm = { ...data }
  },
  // SET_EVENT_INFORMATION_FORM(state, data) {
  //   state.eventInformationForm = { ...data }
  // },
  // SET_ORDER_ITEMS_RENTAL_TABLE(state, data) {
  //   if (data.products) {
  //     data.products.map(item => ({ ...item, amount: 0 }))
  //   }
  //
  //   state.orderItemsTable = data
  // },
  // SET_ORDER_ITEMS_OTHER_CHARGES_TABLE(state, data) {
  //   state.orderItemsOtherChargesTable.otherCharges = data
  // },
  // SET_ORDER_ITEMS_OTHER_CHARGES_TABLE_REF(state, data) {
  //   state.orderItemsOtherChargesTableRef = data
  // },
  // SET_ORDER_ITEMS_OTHER_CHARGES_TABLE_ERROR(state, data) {
  //   state.otherChargeError = data
  // },
  // SET_RECEIVE_WAREHOUSE_FORM(state, data) {
  //   state.receiveWarehouseForm = { ...data }
  // },
  SET_TOTALS_FORM(state, data) {
    state.totalsForm = { ...data }
  },
  // SET_ON_ERROR(state, data) {
  //   state.onError = { ...data }
  // },
  // SET_RECEIVE_ATTACHMENTS(state, data) {
  //   state.receiveAttachments = data
  // },
  // SET_UPLOAD_INVOICE_COLLAPSE_IS_OPEN(state, bool) {
  //   state.uploadInvoiceCollapseIsOpen = bool
  // },
  // SET_UPLOADED_PO_INVOICE(state, data) {
  //   state.POInvoice = data
  // },
  SET_OTHER_CHARGES_ITEMS(state, data) {
    state.otherChargesItems = data
  },
  SET_CREDIT_ITEMS_TABLE(state, data) {
    state.creditItemsTable.creditItems = data
  },
}
