import { reactive } from '@vue/composition-api'

export default {
  quote: {},
  quotes: [],
  quoteForm: {},
  creditItemsTable: {
    creditItems: [],
  },
  // stockItems: {
  //   products: [],
  // },
  // otherChargesItems: {
  //   otherCharges: [],
  // },
  orderInformationForm: {},
  customerInformationForm: {
    attachments: [],
  },
  // insuranceInformationForm: {},
  dispatchAndReturnForm: reactive({}),
  // eventInformationForm: {},
  // orderItemsRentalTable: {
  //   products: [],
  // },
  // orderItemsTable: {
  //   products: [],
  // },
  // orderItemsOtherChargesTable: {
  //   otherCharges: [],
  // },
  // orderItemsOtherChargesTableRef: null,
  totalsForm: {},
  // onError: {},

  //  Received Quote From Supplier
  // receiveWarehouseForm: {},
  // receiveAttachments: [],

  //  Purchase Order  for SUPPLIER

  // uploadInvoiceCollapseIsOpen: false,
  // POInvoice: { },
  //
  // otherChargeError: null,
}
